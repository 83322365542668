// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** primary - leviat indigo **/
	--ion-color-primary: #201547;
	--ion-color-primary-rgb: 32,21,71;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1c123e;
	--ion-color-primary-tint: #362c59;

  /** secondary - leviat sky **/
	--ion-color-secondary: #05C3DD;
	--ion-color-secondary-rgb: 5,195,221;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,0255;
	--ion-color-secondary-shade: #04acc2;
	--ion-color-secondary-tint: #1ec9e0;

  /** tertiary - leviat purple **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success - leviat teal **/
	--ion-color-success: #08b0a0;
	--ion-color-success-rgb: 8,176,160;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #079b8d;
	--ion-color-success-tint: #21b8aa;

  /** warning - leviat orange **/
	--ion-color-warning: #f07f3c;
	--ion-color-warning-rgb: 240,127,60;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d37035;
	--ion-color-warning-tint: #f28c50;

  /** danger - leviat red **/
	--ion-color-danger: #e94249;
	--ion-color-danger-rgb: 233,66,73;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cd3a40;
	--ion-color-danger-tint: #eb555b;

  /** dark - not in leviat palette **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium - leviat stone **/
	--ion-color-medium: #707372;
	--ion-color-medium-rgb: 112,115,114;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #636564;
	--ion-color-medium-tint: #7e8180;

  /** light - leviat steel **/
	--ion-color-light: #c7c8c7;
	--ion-color-light-rgb: 199,200,199;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #afb0af;
	--ion-color-light-tint: #cdcecd;

  /** leviat lime */
	--ion-color-lime: #CEDC00;
	--ion-color-lime-rgb: 206,220,0;
	--ion-color-lime-contrast: #000000;
	--ion-color-lime-contrast-rgb: 0,0,0;
	--ion-color-lime-shade: #b5c200;
	--ion-color-lime-tint: #d3e01a;
}
