/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '@angular/cdk/overlay-prebuilt.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

$columns: 200;

ion-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

ion-item {
  cursor: pointer
}

.c-pointer {
  cursor: pointer
}

.menu-item-active {
  font-weight: bold;
}


@mixin fsp {
  @for $i from 1 through $columns {
    .fsp-#{$i} {
      font-size: #{$i}px !important;
    }
  }
}

@include fsp;

@mixin fs {
  @for $i from 1 through $columns {
    .fs-#{$i} {
      font-size: #{$i}rem !important;
    }
  }
}

@include fs;


@mixin pad {
  @for $i from 1 through $columns {
    .p-#{$i} {
      padding: #{$i}rem !important;
    }
  }
}

@include pad;


@mixin pad-x {
  @for $i from 1 through $columns {
    .px-#{$i} {
      padding: #{$i}rem !important;
    }
  }
}

@include pad-x;


@mixin pad-top-x {
  @for $i from 1 through $columns {
    .pt-#{$i} {
      padding-top: #{$i}rem !important;
    }
  }
}

@include pad-top-x;

@mixin pad-bottom-x {
  @for $i from 1 through $columns {
    .pb-#{$i} {
      padding-bottom: #{$i}rem !important;
    }
  }
}

@include pad-bottom-x;

@mixin pad-left-x {
  @for $i from 1 through $columns {
    .pl-#{$i} {
      padding-left: #{$i}rem !important;
    }
  }
}

@include pad-left-x;

@mixin pad-right-x {
  @for $i from 1 through $columns {
    .pr-#{$i} {
      padding-right: #{$i}rem !important;
    }
  }
}

@include pad-right-x;

$mart: 50;


@mixin mar-x {
  @for $i from 1 through $columns {
    .mx-#{$i} {
      margin-right: #{$i}rem !important;
      margin-left: #{$i}rem !important;
    }
  }
}

@include mar-x;


@mixin mar-top-x {
  @for $i from 1 through $mart {
    .mt-#{$i} {
      margin-top: #{$i}rem !important;
    }
  }
}

@include mar-top-x;

$marr: 50;

@mixin mar-right-x {
  @for $i from 1 through $marr {
    .mr-#{$i} {
      margin-right: #{$i}rem !important;
    }
  }
}

@include mar-right-x;

$marb: 50;

@mixin mar-bottom-x {
  @for $i from 1 through $marb {
    .mb-#{$i} {
      margin-bottom: #{$i}rem !important;
    }
  }
}

@include mar-bottom-x;

$marl: 50;

@mixin mar-left-x {
  @for $i from 1 through $marl {
    .ml-#{$i} {
      margin-left: #{$i}rem !important;
    }
  }
}

@include mar-left-x;

$width: 100;

@mixin width-perc-x {
  @for $i from 1 through $width {
    .w-#{$i} {
      width: #{$i}#{"%"} !important;
    }
  }

}

@include width-perc-x;

@mixin width-x {
  @for $i from 1 through $width {
    .width-#{$i} {
      width: #{$i}px !important;
    }
  }
}

@include width-x;


@mixin mxp-x {
  @for $i from 1 through $width {
    .mxp-#{$i} {
      margin-right: #{$i}px !important;
      margin-left: #{$i}px !important;
    }
  }
}

@include mxp-x;





.float-right {
  float: right;
}


.modal-c,
.modal-group {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}


// .modal-c::part(content) {
//   width: 60%;
//   height: 80%;
// }

// .modal-group::part(content) {
//   width: 40%;
//   height: 60%;
// }



.popover-content::part(content) {
  width: 200px !important;
}

ion-item {
  ion-icon {
    z-index: 100 !important;
  }
}

.label-stacked {
  font-size: 19px !important;
  color: var(--ion-color-primary) !important;
  font-weight: bold;
}

ion-label {
  font-size: 14px !important;
  color: var(--ion-color-primary) !important;
  font-weight: bold;
}

.b-all {
  border: solid 1px var(--ion-color-primary);
}

hr {
  background-color: black;
  height: 1px;
  width: 100%;
}

.align-items-center {
  align-items: center;
}



// tooltip related


.tooltip-example {
  text-align: center;
  padding: 0 50px;
}

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: var(--ion-color-primary);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}




.text-primary {
  color: var(--ion-color-primary);
  font-weight: 500;
}

.mat-list-item-content {
  display: flex;
  align-items: center;
}

.mat-tree {
  padding: 8px;
}


.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.text-center {
  text-align: center;
}

// media queries
.d-desktop {
  display: none !important;
}

.d-mobile {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .d-desktop {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }

  .modal-c::part(content) {
    width: 95%;
    height: 90%;
  }


  .modal-group::part(content) {
    width: 85%;
    height: 15%;
  }


  .mat-drawer.mat-drawer-side {
    width: -webkit-fill-available !important;
  }


  .mobile-label {
    // width: 80px;
    display: inline-block;
    font-weight: bold;
    margin: 5px;
  }

  .mat-header-row {
    display: none;
  }

  .mat-row {
    display: flex !important;
    flex-direction: column;
    align-items: start;
    padding: 8px 8px;
    height: 100% !important;
  }

  .mat-cell {
    width: -webkit-fill-available !important;
    padding-left: unset !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset !important;
  }



  .d-grid-table {
    display: grid;
    grid-template-columns: 65% 35%;
    align-items: center;
  }

  .d-grid-table-2 {
    display: grid;
    grid-template-rows: 75% 10%;
  }
}


@media only screen and (min-width: 768px) {


  .d-grid-table-2 {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
  }

  .d-grid-table {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
  }

  .mobile-label {
    display: none;
  }

  .d-mobile {
    display: none !important;
  }

  .d-desktop {
    display: block !important;
  }

  .modal-c::part(content) {
    width: 60%;
    height: 90%;
  }

  .modal-group::part(content) {
    width: 35%;
    height: 18%;
  }

}






.mat-drawer-side {
  border: none !important;
}



.ng-select.custom-new {
  border: 0px;
  min-height: 0px;
  border-radius: 0;
  width: 50%;
  margin:5px;
  float:right;
}


.ng-select.custom {
  border: 0px;
  min-height: 0px;
  border-radius: 0;
  width: 85%
}

.ng-select.custom .ng-select-container {
  border-radius: 8px;
  height: 32px !important;
}

.ng-select-single .ng-select-container {
  height: 32px !important;
}

.ng-select.custom-2 {
  border: 0px;
  min-height: 0px;
  border-radius: 0;
  width: 86%;
  padding: 6px;
}

.ng-select.custom-2 .ng-select-container {
  border-radius: 8px;
  height: 32px !important;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: unset !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}


.b-red {
  border: solid red 1px !important;
}

.c-red {
  color: red !important;
}

.justify-content-end {
  justify-content: end !important;
}


.active-link {
  span {
    color: var(--ion-color-secondary) !important;
  }

}

.text-align-end {
  text-align: end;
}

.capitalize {
  text-transform: capitalize !important;
}

.pointer-none {
  pointer-events: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
